import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import { tidyDate } from '../../utils/AppHelpers';
import { MdSettings } from 'react-icons/md';

export default function AdminGamificationLeaderboardPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [leaderboardData, setLeaderboardData] = useState()
    // const { inspections_checklist_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const [searchQuery, setSearchQuery] = useState("")


    const fetchLeaderboardData = async () => {
        let endpoint = config.endpoint + "/game/admin/leaderboard"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            setLeaderboardData(data.data)
        }
    }

    useEffect(() => {
        fetchLeaderboardData()
    }, [])

    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Gamification"} />
            <div className='container'>
                <AdminHeader title={"Overview"} />
                <div className="component" style={{ position: "sticky", top: top }}>
                    <div>
                        <p className="component-title">Leaderboard</p>
                        <p className="component-subtitle">Gamification</p>
                    </div>
                    <button className='backgroundLess row gap-10'
                        onClick={() => {
                            navigate("/admin/gamification/settings")
                        }}
                    >
                        <MdSettings style={{}} />
                        Gamification Settings</button>
                </div>
                <div className="inner-container">
                    <div style={{ width: "100%" }}>
                        <input type="text" className="text-input mb-10" placeholder='Search...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                        <p style={{ fontSize: 13, margin: 0, padding: 0, textAlign: "center" }}>{tidyDate(leaderboardData?.game_start)} to {tidyDate(leaderboardData?.game_end)}</p>
                        {leaderboardData?.positions?.map((item, index) => {
                            if (searchQuery.length > 0 && !JSON.stringify(item)?.toLowerCase().includes(searchQuery.toLowerCase())) {
                                return null
                            }
                            return (
                                <div className="component cursor-pointer" style={{ padding: 10, marginTop: 10 }} key={index}
                                    onClick={() => {
                                        navigate("/admin/users/id/" + item?.id)
                                    }}
                                >
                                    <p style={{ marginRight: 10 }}>{index + 1}.</p>
                                    <div className="row justifySplit alignCenter" style={{ width: "100%" }}>
                                        <div>
                                            <p className="b" style={{ fontSize: 12 }}>{item?.username || item?.email || item?.telegram}</p>
                                        </div>
                                        <p style={{ color: "gray" }}>{item?.points} pts</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
