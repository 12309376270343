import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./styles/gStyles.css"
import IndexPage from './pages/IndexPage';
import AuthPage from './pages/AuthPage';
import AdminHomePage from './pages/admin/AdminHomePage';
import { ToastContainer } from 'react-toastify';
import AdminLocationPage from './pages/admin/AdminLocationPage';
import AdminLocationDetailPage from './pages/admin/AdminLocationDetailPage';
import UserReportingPage from './pages/user/UserReportingPage';
import AdminChecklistPage from './pages/admin/AdminChecklistPage';
import AdminChecklistDetailPage from './pages/admin/AdminChecklistDetailPage';
import UserAuthPage from './pages/user/UserAuthPage';
import AdminReportPage from './pages/admin/AdminReportPage';
import AdminReportDetailPage from './pages/admin/AdminReportDetailPage';
import AdminInspectionPage from './pages/admin/AdminInspectionPage';
import AdminInspectionDetailPage from './pages/admin/AdminInspectionDetailPage';
import AdminGamificationSettingsPage from './pages/admin/AdminGamificationSettingsPage';
import AdminUsersPage from './pages/admin/AdminUsersPage';
import AdminUserDetailPage from './pages/admin/AdminUserDetailPage';
import UserHomePage from './pages/user/UserHomePage';
import AdminControlPanelPage from './pages/admin/AdminControlPanelPage';
import AdminGamificationLeaderboardPage from './pages/admin/AdminGamificationLeaderboardPage';
import GoPage from './pages/user/GoPage';
import AdminCampusPage from './pages/admin/AdminCampusPage';
import AdminCampusDetailPage from './pages/admin/AdminCampusDetailPage';
import UserProfilePage from './pages/user/UserProfilePage';
import AdminToolsReportsPage from './pages/admin/AdminToolsReportsPage';
import AdminUserManagePage from './pages/admin/AdminUserManagePage';
import AdminFirstAidPage from './pages/admin/AdminFirstAidPage';
import AdminFirstAidDetailPage from './pages/admin/AdminFirstAidDetailPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthPage pageType={"login"} />} />

        {/* Redirect page */}
        <Route path="/go" element={<GoPage />} />
        
        <Route path="/auth/login" element={<AuthPage pageType={"login"} />} />
        <Route path="/auth/register" element={<AuthPage pageType={"register"} />} />

        {/* User */}
        <Route path="/user/report" element={<UserReportingPage />} />
        <Route path="/user/login" element={<UserAuthPage state={"login"} />} />
        <Route path="/user/register" element={<UserAuthPage state={"register"} />} />
        <Route path="/user/reset-password/:token" element={<UserAuthPage state={"reset-password"} />} />

        <Route path="/user/home" element={<UserHomePage />} />
        <Route path="/user/profile" element={<UserProfilePage />} />

        {/* Admin */}
        <Route path="/admin" element={<AdminHomePage />} />
        <Route path="/admin/reports" element={<AdminReportPage />} />
        <Route path="/admin/reports/id/:report_id" element={<AdminReportDetailPage />} />


        {/* Tools */}
        <Route path="/admin/tools/locations" element={<AdminLocationPage />} />
        <Route path="/admin/tools/locations/:location_id" element={<AdminLocationDetailPage />} />
        <Route path="/admin/tools/campus" element={<AdminCampusPage />} />
        <Route path="/admin/tools/campus/:campus_id" element={<AdminCampusDetailPage />} />
        <Route path="/admin/tools/reports" element={<AdminToolsReportsPage />} />

        {/* Control Panel */}
        <Route path="/admin/tools/control-panel" element={<AdminControlPanelPage />} />

        {/* Checklist */}
        <Route path="/admin/checklist" element={<AdminChecklistPage />} />
        <Route path="/admin/checklist/:checklist_id" element={<AdminChecklistDetailPage />} />

        {/* Staff&Users */}
        <Route path="/admin/users" element={<AdminUsersPage />} />
        <Route path="/admin/users/id/:user_id" element={<AdminUserDetailPage />} />
        <Route path="/admin/users/manage" element={<AdminUserManagePage />} />
        {/* <Route path="/admin/users" element={<AdminUsersPage />} /> */}

        {/* Inspection */}
        <Route path="/admin/inspections" element={<AdminInspectionPage />} />
        <Route path="/admin/inspections/id/:inspection_id" element={<AdminInspectionDetailPage />} />

        {/* First Aid */}
        <Route path="/admin/first-aid" element={<AdminFirstAidPage />} />
        <Route path="/admin/first-aid/id/:first_aid_id" element={<AdminFirstAidDetailPage />} />

        {/* Gamification Settings */}
        <Route path="/admin/gamification/settings" element={<AdminGamificationSettingsPage />} />
        <Route path="/admin/gamification/leaderboard" element={<AdminGamificationLeaderboardPage />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
