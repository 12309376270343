import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';

export default function AdminChecklistPage() {
    const navigate = useNavigate()
    const [responseData, setResponseData] = useState([])

    useEffect(() => {
        fetchChecklists()
    }, [])

    const fetchChecklists = async () => {
        let endpoint = config.endpoint + `/checklist/`

        const response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let data = await response.json()

        if (data.status === 200) {
            setResponseData(data.data)
        }
    }

    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Tools"}/>
            <div className='container'>
                <AdminHeader />
                <div className="component" style={{ position: "sticky", top: document?.getElementById("admin-header")?.clientHeight }}>
                    <div>
                        <p className="component-title">Checklist</p>
                        <p className="component-subtitle">forms</p>
                    </div>
                    <button className='secondary-button'
                        onClick={() => {
                            navigate('/admin/checklist/create')
                        }}
                    >New Checklist</button>
                </div>
                <div className="inner-container">
                    {responseData && responseData.map((checklist, index) => {
                        return (
                            <div className="card mb-10 cursor-pointer"
                                key={index}
                                onClick={() => {
                                    navigate(`/admin/checklist/${checklist?.id}`, { state: checklist })
                                }}
                            >
                                <div className="row justifySplit alignCenter">
                                    <div>
                                        <p className="card-title" style={{ padding: 0, margin: 0 }}>{checklist?.checklist_name}</p>
                                        <p className="card-title" style={{ marginBottom: 0, color: "gray", padding: 0 }}>{checklist?.checklist_items?.length} items</p>
                                    </div>
                                    {/* <button className="backgroundLess"
                                        onClick={() => {
                                            navigate(`/admin/checklist/${checklist?.id}`, { state: checklist })
                                        }}
                                    >edit</button> */}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
