import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import Switch from "react-switch";
import { isMobile } from '../../utils/AppHelpers';

export default function AdminControlPanelPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })

    const [isLoaded, setIsLoaded] = useState(false)

    // const { inspections_checklist_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)
    const [bannerAnnouncementData, setBannerAnnouncementData] = useState("")

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })



    const [data, setData] = useState({
        allow_login_student: true,
        allow_login_staff: true,
        allow_register_student: true,
        allow_register_staff: true,
        allow_report: true,
        allow_inspection: true,
        allow_suggestions: true,
        allow_compliment: true,
        allow_firstaid: true,
        show_banner_announcement: false,
        banner_announcement: ""
    })

    const fetchConfiguration = async () => {

        let endpoint = config.endpoint + "/config/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })
        let data = await response.json()
        if (data.status == 200) {
            setData(data.data)
            setBannerAnnouncementData(data?.data?.banner_announcement)
            setIsLoaded(true)
        } else {
            notify({ type: "error", message: data.message })
        }
    }

    useEffect(() => {
        fetchConfiguration()
    }, [])

    const updateConfiguration = async () => {
        if (!isLoaded) return
        let endpoint = config.endpoint + "/config/"
        let response = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })
        let fetchData = await response.json()
        if (fetchData.status == 200) {
            notify({ type: "success", message: `Platform settings updated` })
        } else {
            notify({ type: "error", message: fetchData.message })
        }
    }

    useEffect(() => {
        updateConfiguration()
    }, [data])

    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Tools"} />
            <div className='container'>
                <AdminHeader title={"Overview"} />
                <div className="component" style={{ position: "sticky", top: top }}>
                    <div>
                        <p className="component-title">Platform</p>
                        <p className="component-subtitle">Settings</p>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="card mb-10">
                        <p className="card-title">Allow Login</p>
                        <p className="card-subtitle">Allow users to login to the platform</p>
                        <div className="row justifySplit mb-10 alignCenter">
                            <p className="card-content-title">User Dashboard Login</p>
                            <Switch checked={data.allow_login_student == "1"} onChange={(e) => {
                                setData({
                                    ...data,
                                    allow_login_student: e ? "1" : "0"
                                })
                            }} />
                        </div>
                        {/* <div className="row justifySplit mb-10 alignCenter">
                            <p className="card-content-title">Staff</p>
                            <Switch checked={data.allow_login_staff == "1"} onChange={(e) => {
                                setData({
                                    ...data,
                                    allow_login_staff: e ? "1" : "0"
                                })
                            }} />
                        </div> */}
                    </div>
                    <div className="card mb-10">
                        <p className="card-title">Banner Announcement</p>
                        <p className="card-subtitle">Show banner announcement on the platform</p>
                        <div className="row justifySplit mb-10 alignCenter">
                            <p className="card-content-title">Show Banner Announcement</p>
                            <Switch checked={data.show_banner_announcement == "1"} onChange={(e) => {
                                setData({
                                    ...data,
                                    show_banner_announcement: e ? "1" : "0"
                                })
                            }} />
                        </div>
                        <div className={`${isMobile(width) ? "column " : "row justifySplit"}  mb-10 mt-20`} style={{ width: "100%", alignItems: isMobile(width) && "flex-start", }}>
                            <p className="card-content-title" style={{ textAlign: "left", marginBottom: isMobile(width) && "10px" }}>Banner Announcement</p>
                            <div style={{ width: "100%", display: "flex", justifyContent: "right", flexDirection: "column", alignItems: "flex-end" }}>
                                <textarea className="textarea-input" value={bannerAnnouncementData} onChange={(e) => {
                                    setBannerAnnouncementData(e.target.value)
                                }} />
                                <button className="primary-button mt-10" onClick={() => {
                                    setData({
                                        ...data,
                                        banner_announcement: bannerAnnouncementData
                                    })
                                }}>Update</button>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-10">
                        <p className="card-title">Allow Registration</p>
                        <p className="card-subtitle">Allow users to register to the platform</p>
                        <div className="row justifySplit mb-10 alignCenter">
                            <p className="card-content-title">User Dashboard</p>
                            <Switch checked={data.allow_register_student == "1"} onChange={(e) => {
                                setData({
                                    ...data,
                                    allow_register_student: e ? "1" : "0"
                                })
                            }} />
                        </div>
                        <div className="row justifySplit mb-10 alignCenter">
                            <p className="card-content-title">Administrative Dashboard</p>
                            <Switch checked={data.allow_register_staff == "1"} onChange={(e) => {
                                setData({
                                    ...data,
                                    allow_register_admin: e ? "1" : "0"
                                })
                            }} />
                        </div>
                    </div>
                    <div className="card mb-10">
                        <p className="card-title">Reporting and Inspection</p>
                        <p className="card-subtitle">Allow users to report to the platform</p>
                        <div className="row justifySplit mb-10 alignCenter">
                            <p className="card-content-title">Incident/Hazard</p>
                            <Switch checked={data.allow_report == "1"} onChange={(e) => {
                                setData({
                                    ...data,
                                    allow_report: e ? "1" : "0"
                                })
                            }} />
                        </div>
                        <div className="row justifySplit mb-10 alignCenter">
                            <p className="card-content-title">Inspection</p>
                            <Switch checked={data.allow_inspection == "1"} onChange={(e) => {
                                setData({
                                    ...data,
                                    allow_inspection: e ? "1" : "0"
                                })
                            }} />
                        </div>
                        <div className="row justifySplit mb-10 alignCenter">
                            <p className="card-content-title">Compliment</p>
                            <Switch checked={data.allow_compliment == "1"} onChange={(e) => {
                                setData({
                                    ...data,
                                    allow_compliment: e ? "1" : "0"
                                })
                            }} />
                        </div>
                        <div className="row justifySplit mb-10 alignCenter">
                            <p className="card-content-title">First Aid</p>
                            <Switch checked={data.allow_firstaid == "1"} onChange={(e) => {
                                setData({
                                    ...data,
                                    allow_firstaid: e ? "1" : "0"
                                })
                            }} />
                        </div>
                        <div className="row justifySplit mb-10 alignCenter">
                            <p className="card-content-title">Suggestions</p>
                            <Switch checked={data.allow_suggestions == "1"} onChange={(e) => {
                                setData({
                                    ...data,
                                    allow_suggestions: e ? "1" : "0"
                                })
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
