import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import { calculate_time_since, isMobile, tidyDate } from '../../utils/AppHelpers';
import { MdDownloadForOffline, MdPersonAdd } from 'react-icons/md';

export default function AdminUsersPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    // query string
    const [filter, setFilter] = useState(new URLSearchParams(window.location.search).get('filter') || "all")
    // const { inspections_checklist_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)
    const [userData, setUserData] = useState([])
    const [search, setSearch] = useState("")

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const fetch_all_users = async () => {
        let endpoint = config.endpoint + "/users/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await response.json()
        if (data.status === 200) {
            setUserData(data.data?.reverse())
        }
    }

    const exportUsers = async () => {
        let endpoint = config.endpoint + `/users/export`

        const response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let data = await response.json()

        if (data.status === 200) {
            let url = config.endpoint + data?.data?.file_path
            window.open(url, "_blank")
        }
    }

    useEffect(() => {
        fetch_all_users()
    }, [])


    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Admin & Users"}/>
            <div className='container'>
                <AdminHeader title={"Overview"} />
                <div className="component" style={{ position: "sticky", top: top }}>
                    <div>
                        <p className="component-title">Users</p>
                        <p className="component-subtitle">All Users on this platform</p>
                    </div>
                    {/* <button className='secondary-button'
                        onClick={() => {
                            navigate('/admin/users/id/new')
                        }}
                    >New User</button> */}
                    <div className="row gap-20 alignCenter center" style={{ maxWidth: "100%", paddingLeft: isMobile(window.innerWidth) ? "60px" : "0px", overflowX: "scroll" }}>
                        <button className='backgroundLess row alignCenter gap-10'
                            onClick={() => {
                                exportUsers()
                            }}
                        >
                            <MdDownloadForOffline size={20} />
                            <p>Export Users</p>
                        </button>
                        <button className='backgroundLess row gap-10'
                            onClick={() => {
                                navigate('/admin/users/id/new')
                            }}

                        >
                            <MdPersonAdd style={{}} />
                            Add User</button>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="row gap-10 alignCenter mb-20">
                        <input
                            className="text-input"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                        />
                        <select
                            className="select-input"
                            value={filter}
                            style={{ width: 200 }}
                            onChange={(e) => {
                                setFilter(e.target.value)
                            }}
                        >
                            <option value="all">All</option>
                            <option value="user">Users</option>
                            <option value="staff">Admin</option>
                        </select>
                    </div>
                    {userData?.map((user, index) => {
                        if (filter === "user" && user?.account_type !== "user") return
                        if (filter === "staff" && (user?.account_type !== "staff" && user?.account_type !== "admin")) return
                        if (search !== "" && !JSON.stringify(user).toLowerCase().includes(search.toLowerCase())) return
                        let color = "gray"
                        let account_name = user?.account_type
                        if (user?.account_type === "user") {
                            color = "green"
                            account_name = "User"
                        }
                        if (user?.account_type === "staff") {
                            color = "orange"
                            account_name = "Admin"
                        }
                        if (user?.account_type === "admin") {
                            color = "red"
                            account_name = "Super Admin"
                        }

                        return (
                            <div className="card mb-10 cursor-pointer"
                                onClick={() => {
                                    navigate(`/admin/users/id/${user?.id}`)
                                }}
                            >
                                <div className="row alignCenter justifySplit" style={{ display: "flex", alignItems: "center" }}>
                                    <div>
                                        <p className="card-title" style={{ margin: 0, padding: 0 }}>{user?.email || user?.username || user?.telegram}</p>
                                        <p className="card-title" style={{ marginBottom: 0, color: "gray", padding: 0 }}>{calculate_time_since(user?.createdAt)}, last logged in {calculate_time_since(user?.last_login)}</p>
                                    </div>
                                    <div className="row gap-10 alignCenter">
                                        <p style={{ backgroundColor: color, padding: "5px 20px", borderRadius: 20, fontSize: 13, color: "white", fontWeight: "bold" }}>{account_name}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
