import { useEffect, useState } from "react"
import config from "../app-config"

export const logout = () => {
    let endpoint = config.endpoint + "/auth/logout"
    let response = fetch(endpoint, {
        credentials: "include",
        method: "POST",

    })
    localStorage.removeItem("user")
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = "/"
}

export const useFetchAuthorisation = (account_type) => {
    const [isAuthorised, setIsAuthorised] = useState(false);

    useEffect(() => {
        const fetchAuthorisation = async () => {
            try {
                const endpoint = config.endpoint + "/auth/authorisation";
                const response = await fetch(endpoint, {
                    credentials: "include",
                    method: "POST",
                });
                const data = await response?.json();
                if (data.status === 200) {
                    sessionStorage.setItem("user", JSON.stringify(data.data));
                    if (convertToRole(account_type) <= convertToRole(data?.data?.account_type)) {
                        setIsAuthorised(true);
                    } else {
                        setIsAuthorised(false);
                    }
                } else {
                    setIsAuthorised(false);
                }
            } catch (error) {
                setIsAuthorised(false);
            }
        };
        fetchAuthorisation();
    }, [account_type]);
    return isAuthorised;
};


export const useLogin = (account_type) => {
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        const fetchAuthorisation = async () => {
            try {
                const endpoint = config.endpoint + "/auth/authorisation";
                const response = await fetch(endpoint, {
                    credentials: "include",
                    method: "POST",
                });
                const data = await response?.json();
                if (data.status === 200) {
                    sessionStorage.setItem("user", JSON.stringify(data.data));
                    setIsLogin(true);
                } else {
                    setIsLogin(false);
                }
            } catch (error) {
                setIsLogin(false);
            }
        };

        fetchAuthorisation();
    }, [account_type]);
    return isLogin;
}

export const useFetchUser = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const endpoint = config.endpoint + "/auth/authorisation";
                const response = await fetch(endpoint, {
                    credentials: "include",
                    method: "POST",
                });
                const data = await response?.json();
                if (data.status === 200) {
                    sessionStorage.setItem("user", JSON.stringify(data.data));
                    setUser(data.data);
                } else {
                    setUser(null);
                }
            } catch (error) {
                setUser(null);
            }
        };
        fetchUser();
    }, []);
    return user;
}

const convertToRole = (role) => {
    switch (role) {
        case "student":
            return 1;
        case "staff":
            return 2;
        case "admin":
            return 3;
        default:
            return 0;
    }
}


