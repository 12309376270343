import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../app-config';
import { MdAdd, MdDownloadForOffline, MdImportExport, MdShare, MdUpload } from 'react-icons/md';
import notify from '../../utils/IANotification';
import { ImCross } from 'react-icons/im';
import { FaTrash } from 'react-icons/fa';
import { isMobile } from '../../utils/AppHelpers';

export default function AdminLocationPage() {
    const navigate = useNavigate()
    const [responseData, setResponseData] = useState([])
    const [query, setQuery] = useState("")
    const [fileSelected, setFileSelected] = useState()
    const [pageState, setPageState] = useState("view") // view, manage
    const [selectedLocations, setSelectedLocations] = useState([])

    const fetchLocations = async () => {
        let endpoint = config.endpoint + `/location/`

        const response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let data = await response.json()

        if (data.status === 200) {
            setResponseData(data.data)
        }
    }


    const exportLocations = async () => {
        let endpoint = config.endpoint + `/location/export`

        const response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let data = await response.json()

        if (data.status === 200) {
            let url = config.endpoint + data?.data?.file_path
            // download from url
            // let a = document.createElement("a")
            // a.style.display = "none"
            // a.href = url
            // a.download = "locations.csv"
            // document.body.appendChild(a)
            // a.click()

            window.open(url, "_blank")
        }
    }

    useEffect(() => {
        fetchLocations()
    }, [])


    const uploadCSVFile = async (file_selected) => {

        if (!file_selected) {
            notify({ type: "error", message: "Please select a file" })
            return
        }
        let formData = new FormData()
        formData.append("file", file_selected)
        let endpoint = config.endpoint + `/location/csv/upload`
        notify({ type: "info", message: "Uploading file..." })
        let response = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            body: formData
        })
        let data = await response.json()
        setFileSelected(null)
        if (data.status === 200) {
            notify({ type: "success", message: data.message })
            fetchLocations()
        } else {
            notify({ type: "error", message: data.message })
        }
    }



    const deleteLocationsBulk = async () => {
        console.log(JSON.stringify(selectedLocations))
        let endpoint = config.endpoint + `/location/bulk`
        let response = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ location_ids: selectedLocations })
        })
        let data = await response.json()
        if (data.status === 200) {
            notify({ type: "success", message: data.message })
            fetchLocations()
            setSelectedLocations([])
            setPageState("view")
        } else {
            notify({ type: "error", message: data.message })
        }
    }

    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Locations"} />
            <div className='container'>
                <AdminHeader />
                <div className="component" style={{ position: "sticky", top: document?.getElementById("admin-header")?.clientHeight, display:"flex", flexDirection:isMobile(window.innerWidth)?"column":"row", alignItems:isMobile(window.innerWidth)? "flex-start" :"center"}}>
                    <div>
                        <p className="component-title">Location</p>
                        <p className="component-subtitle">Campus Locations</p>
                    </div>
                    {pageState === "manage" ? (
                        <div className="row gap-10 alignCenter">
                            <button className='backgroundLess row gap-10'
                                onClick={() => {
                                    setPageState(pageState === "view" ? "manage" : "view")
                                }}

                            >
                                <ImCross style={{}} />
                                Cancel</button>
                            <button className="delete-button" onClick={() => {
                                if (selectedLocations.length === 0) {
                                    toast.error("Please select at least one location")
                                    return
                                }
                                deleteLocationsBulk()
                            }}>Delete</button>
                        </div>) :
                        <div className="row gap-20 alignCenter center" style={{maxWidth:"100%",paddingLeft:isMobile(window.innerWidth) ? "60px": "0px", overflowX:"scroll"}}>
                            <button className='backgroundLess row gap-10'
                                onClick={() => {
                                    setPageState(pageState === "view" ? "manage" : "view")
                                }}
                            >
                                <FaTrash style={{}} />
                                Manage</button>
                            <button className='backgroundLess row alignCenter gap-10'
                                onClick={() => {
                                    document.getElementById("upload-csv").click()
                                }}
                            >
                                <MdUpload size={20} />
                                <p>Upload CSV File</p>
                            </button>
                            <button className='backgroundLess row alignCenter gap-10'
                                onClick={() => {
                                    exportLocations()
                                }}
                            >
                                <MdDownloadForOffline size={20} />
                                <p>Export Locations</p>
                            </button>
                            <input type="file" id='upload-csv' style={{ display: "none" }} onChange={(e) => {
                                // setFileSelected(e.target.files[0])
                                uploadCSVFile(e.target.files[0])
                            }} />
                            {/* <button className='secondary-button'
                                onClick={() => {
                                    navigate('/admin/tools/locations/create')
                                }}
                            >Add Location</button> */}

                            <button className='backgroundLess row alignCenter gap-10'
                                onClick={() => {
                                    navigate('/admin/tools/locations/create')
                                }}
                            >
                                <MdAdd size={20} />
                                <p>Create Location</p>
                            </button>
                        </div>}
                </div>

                <div className="inner-container">
                    <input className="text-input mb-10" placeholder="Search" value={query} onChange={(e) => {
                        setQuery(e.target.value)
                    }} />
                    {responseData && responseData.map((location, index) => {
                        if (query && !JSON.stringify(location)?.toLowerCase().includes(query.toLowerCase())) {
                            return null
                        }
                        return (
                            <div className="card mb-10 cursor-pointer"
                                style={{ width: "100%", border: (selectedLocations?.includes(location?.id) && pageState === "manage") ? "2px solid #00A4EF" : "2px solid transparent", }}

                                onClick={() => {
                                    if (pageState === "manage") {
                                        if (selectedLocations.includes(location?.id)) {
                                            setSelectedLocations(selectedLocations.filter(item => item !== location.id))
                                        } else {
                                            setSelectedLocations([...selectedLocations, location?.id])
                                        }
                                        return
                                    } else {
                                        navigate(`/admin/tools/locations/${location?.id}`, { state: location })
                                    }
                                }}
                            >
                                <div className="row justifySplit alignCenter">
                                    <div>
                                        <p className="card-title" style={{ padding: 0, margin: 0 }}>{location?.location_name}</p>
                                        <p className="card-title" style={{ marginBottom: 0, color: "gray", padding: 0 }}>{location?.campus_data?.campus_name}</p>
                                    </div>
                                    {/* <button className="backgroundLess"
                                        onClick={() => {
                                            navigate(`/admin/tools/locations/${location?.id}`, { state: location })
                                        }}
                                    >edit</button> */}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
