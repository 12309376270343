import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import { isMobile } from '../../utils/AppHelpers';
import { FaBuilding, FaPlus } from 'react-icons/fa';

export default function AdminCampusPage() {
    const navigate = useNavigate()
    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })

    const { state } = useLocation()
    // const { inspections_checklist_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const [responseData, setResponseData] = useState([])

    useEffect(() => {
        fetchCampus()
    }, [])

    const fetchCampus = async () => {
        let endpoint = config.endpoint + `/campus/`

        const response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let data = await response.json()

        if (data.status === 200) {
            setResponseData(data.data)
        }
    }


    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Locations"} />
            <div className='container'>
                <AdminHeader />
                <div className="component" style={{ position: "sticky", top: top }}>
                    <div>
                        <p className="component-title">SIT</p>
                        <p className="component-subtitle">Campus</p>
                    </div>
                    {/* <button className='secondary-button'
                        onClick={() => {
                            navigate('/admin/tools/campus/create')
                        }}
                    >Add Campus</button> */}

                    <button className='backgroundLess row gap-10'
                        onClick={() => {
                            navigate('/admin/tools/campus/create')
                        }}

                    >
                        <FaPlus style={{}} />
                        Add Campus</button>
                </div>
                <div className="inner-container">
                    {responseData && responseData.map((campus, index) => {
                        return (
                            <div className="card cursor-pointer mb-10"
                                onClick={() => {
                                    navigate(`/admin/tools/campus/${campus?.id}`, { state: campus })
                                }}
                            >
                                <p className="card-title" style={{ padding: 0, margin: 0 }}>{campus?.campus_name}</p>
                                <p className="card-title" style={{ marginBottom: 0, color: "gray", padding: 0 }}>{campus?.campus_code}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
