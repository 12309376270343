import React, { useState } from 'react'
import Header from '../components/Header'
import config from '../app-config'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../utils/IANotification';

export default function AuthPage({ pageType }) {
  let navigate = useNavigate()
  const [pageState, setPageState] = useState(pageType) // pageType is either "login", "register", "forgot-password", "reset-password", or "2fa"
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")


  const login = async () => {
    let endpoint = config.endpoint + "/auth/login"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    })

    let data = await fetchResponse.json()

    if (data.status === 200) {
      let account_type = data.data.account_type
      if (["admin", "staff", "superadmin"].includes(account_type)) {
        navigate("/admin")
      }
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const register = async () => {
    let endpoint = config.endpoint + "/auth/register"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    })

    let data = await fetchResponse.json()

    if (data.status === 200) {
      notify({ message: "Your account has been created", type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  return (
    <div>
      <Header />
      <ToastContainer />
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 10, width: "100%" }}>
        <div className='' style={{ width: "100%", maxWidth: 500 }}>
          <div className="column card" style={{ maxWidth: "500px", width: "100%" }}>
            <img src="../logo.png" alt="" style={{ height: 100, width: 100, marginBottom: 10 }} />
            <p style={{ color: "black" }}>SIT Safety Reporting System</p>
            <p style={{ color: "black", fontWeight: "900", fontSize: 20, textAlign: "left", width: "100%", marginBottom: 10, marginTop:20 }}>SIT Admin and Staff</p>
            <div className="input-container">
              <p style={{ color: "black", marginBottom: 5,  }}>Email, Username or Telegram Handle</p>
              <input type="email" className="text-input" placeholder="e.g. abc@mail.com" value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => {
                if (e.key === "Enter") {
                  // focus on password input
                  document.getElementById("password-input").focus()
                }
              }} />
            </div>
            <div className="input-container">
              <p style={{ color: "black", marginBottom: 5 }}>Password</p>
              <input type="password" id='password-input' className="text-input" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => {
                if (e.key === "Enter") login()
              }} />
            </div>
            {/* <button className="backgroundLess">Login with Microsoft</button> */}
            <button className="primary-button mt-40" onClick={() => login()}>Login</button>
            {/* <button className="primary-button mt-40 backgroundLess" onClick={() => register()}>Register</button> */}
            <p style={{fontSize:10, fontStyle:"oblique", textAlign:"left", marginTop:30, width:"100%"}}>version {config.version}<br/><span style={{cursor:"pointer"}} onClick={()=>{window.open(config.website,"_blank")}}>Built by {config.developer}</span></p>
          </div>
        </div>
      </div>
    </div>
  )
}
