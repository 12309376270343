import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import { MdAdd, MdChevronRight, MdClose, MdDelete, MdReorder } from 'react-icons/md';
import config from '../../app-config';
import Switch from "react-switch";
import { useFetchAuthorisation } from '../../utils/AppFunctions';
import { isMobile } from '../../utils/AppHelpers';
import { Draggable } from "react-drag-reorder";

export default function AdminChecklistDetailPage() {
    const navigate = useNavigate()
    // const { state } = useLocation()
    const { checklist_id } = useParams()
    const [headerTopPosition, setHeaderTopPosition] = useState(document?.getElementById('temp-header')?.getBoundingClientRect()?.top)
    const isAuthorised = useFetchAuthorisation("admin")

    const [width, setWidth] = useState(window.innerWidth)

    const [dragged, setDragged] = useState(null)

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })


    let modal = {
        checklist_name: '',
        checklist_description: '',
        checklist_items: [] // {item_name: '', item_description: '', item_type: "radio", item_options: ["Yes", "No", "N/A"], allow_remarks: 0}
    }

    const [checklistDetails, setChecklistDetails] = useState(modal)
    const [locations, setLocations] = useState({})
    const [inspectionChecklistUsage, setInspectionChecklistUsage] = useState([])
    const [firstAidChecklistUsage, setFirstAidChecklistUsage] = useState([])
    const [isReorder, setIsReorder] = useState(false)


    const createChecklist = async () => {
        let endpoint = config.endpoint + "/checklist/"
        let response = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(checklistDetails)
        })
        let data = await response.json()

        if (data.status === 200) {
            toast.success("Successfully created checklist")
            navigate(`/admin/checklist`)
        } else {
            toast.error("Failed to create checklist")
        }
    }

    const saveChecklist = async () => {
        let endpoint = config.endpoint + `/checklist/${checklist_id}`
        let response = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...checklistDetails,
                inspections_checklist_usage: inspectionChecklistUsage,
                first_aid_checklist_usage: firstAidChecklistUsage
            })
        })
        let data = await response.json()

        if (data.status === 200) {
            toast.success("Successfully saved checklist")
            navigate(`/admin/checklist`)
        } else {
            toast.error("Failed to save checklist")
        }
    }

    const fetchChecklistById = async () => {
        if (checklist_id === "create") return
        let endpoint = config.endpoint + `/checklist/id/${checklist_id}`
        let response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setChecklistDetails(data.data)
        }
    }

    const fetchChecklistUsageInspections = async () => {
        if (checklist_id === "create") return
        let endpoint = config.endpoint + `/checklist/usage/inspections/id/${checklist_id}`
        let response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setInspectionChecklistUsage(data.data)
        }
    }

    const fetchChecklistUsageFirstAid = async () => {
        if (checklist_id === "create") return
        let endpoint = config.endpoint + `/checklist/usage/first-aid/id/${checklist_id}`
        let response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setFirstAidChecklistUsage(data.data)
        }
    }

    const fetchLocation = async () => {
        if (checklist_id === "create") return
        let endpoint = config.endpoint + `/location/`
        let response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            let group = {}
            let locationData = data.data
            for (let i = 0; i < locationData.length; i++) {
                let campusName = locationData[i]?.campus_data?.campus_name ?? "No Campus"
                if (!group[campusName]) {
                    group[campusName] = []
                }

                let temp = [...group[campusName]]
                temp.push(locationData[i])
                group[campusName] = temp
            }

            setLocations(group)
        }
    }

    const deleteChecklist = async () => {
        let endpoint = config.endpoint + `/checklist/${checklist_id}`
        let response = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let data = await response.json()

        if (data.status === 200) {
            toast.success("Successfully deleted checklist")
            navigate(`/admin/checklist`)
        } else {
            toast.error("Failed to delete checklist")
        }
    }

    const markAsDefault = async () => {
        let endpoint = config.endpoint + `/checklist/default/${checklist_id}`
        let response = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let data = await response.json()

        if (data.status === 200) {
            toast.success("Successfully marked as default")
        } else {
            toast.error("Failed to mark as default")
        }
    }

    useEffect(() => {
        fetchChecklistById()
        setHeaderTopPosition(document?.getElementById('temp-header')?.getBoundingClientRect()?.top)
        fetchChecklistUsageInspections()
        fetchChecklistUsageFirstAid()
        fetchLocation()
    }, [])


    const ChecklistOverview = () => {
        return (
            <div className="modal" style={{ width: isMobile(width) && "100%", display: "flex", alignItems: isMobile(width) ? "flex-start" : "center", justifyContent: isMobile(width) ? "flex-end" : "center" }}>
                <div className="modal-content" style={{ width: isMobile(width) ? "100%":"500px", height: isMobile(width) && "100%", overflowY:"scroll"}}>
                    <div className="modal-header row justifySplit  mb-10">
                        <p className="modal-title">Rearrange Checklist Items</p>
                        <button className="backgroundLess" onClick={() => {
                            setIsReorder(false)
                        }}>
                            <MdClose color='black' size={20} />
                        </button>
                    </div>
                    <div className="modal-body center">
                       {checklistDetails.checklist_items.length > 0 && 
                          <Draggable
                            onPosChange={(curr, prev) => {
                                let temp = [...checklistDetails.checklist_items]
                                temp.splice(curr, 0, temp.splice(prev, 1)[0])
                                setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                            }}
                          >
                            {checklistDetails.checklist_items.map((item, index) => {
                                return (
                                    <div className="row alignStart card justifySplit">
                                        <p className='' style={{fontWeight:"900", fontSize:12}}>{item.item_name}</p>
                                        <div className="row gap-10 alignCenter">
                                            <MdAdd className="icon" size={20} color='black' onClick={() => {
                                                let temp = [...checklistDetails.checklist_items]
                                                temp.splice(index + 1, 0, { item_name: '', item_description: '', item_type: "radio", item_options: ["Yes", "No"], allow_remarks: 0 })
                                                setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                            }} />
                                            <MdDelete className="icon" size={20} color='black' onClick={() => {
                                                let temp = [...checklistDetails.checklist_items]
                                                temp.splice(index, 1)
                                                setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                            }} />
                                        </div>
                                    </div>
                                )
                            })}
                        </Draggable>
                       }

                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Tools"} />
            <div className='container'>
                <AdminHeader />
                {isReorder && <ChecklistOverview />}
                <div className="component" style={{ position: "sticky", top: document?.getElementById("admin-header")?.clientHeight, zIndex: 5 }}>
                    <div>
                        <p className="component-title">Checklist</p>
                        <p className="component-subtitle">{checklist_id === "create" ? "Create" : "Edit"} Checklist</p>
                    </div>
                    {!isMobile(width) && <div className="row gap-10 alignCenter">
                        {isAuthorised && checklistDetails?.is_default != 1 && <button className='backgroundLess'
                            onClick={() => {
                                markAsDefault()
                            }}
                        >Mark as Default</button>}
                        {isAuthorised && checklistDetails?.is_default == 1 && <p>Default Checklist</p>}
                        {checklist_id !== "create" &&
                            <button className='delete-button'
                                onClick={() => {
                                    deleteChecklist()
                                }}
                            >Delete</button>}

                        <button className='primary-button'
                            onClick={() => {
                                if (checklist_id === "create") {
                                    createChecklist()
                                } else {
                                    saveChecklist()
                                }
                            }}
                        >Save</button>

                    </div>}
                </div>
                <div className="inner-container">
                    <div className={`${isMobile(width) ? "column" : "row"} gap-10 alignTop`}>
                        <div className="card"
                            id='temp-header'
                            style={{ position: !isMobile(width) && "sticky", top: headerTopPosition, backgroundColor: "white", zIndex: 0, }}
                        >
                            <p className="card-title">Checklist Information</p>
                            <div className="input-container">
                                <p className="input-label">Checklist Name</p>
                                <input className="text-input" type="text" value={checklistDetails.checklist_name} onChange={(e) => {
                                    setChecklistDetails({ ...checklistDetails, checklist_name: e.target.value })
                                }} />
                            </div>
                            <div className="input-container">
                                <p className="input-label">Checklist Description</p>
                                <textarea className="textarea-input" type="text" value={checklistDetails.checklist_description} onChange={(e) => {
                                    setChecklistDetails({ ...checklistDetails, checklist_description: e.target.value })
                                }} />
                            </div>
                            {checklist_id !== "create" && <>
                                <div className="row justifySplit alignCenter">
                                    <p className="card-title">Checklist Assignment</p>
                                    <div className="row gap-30">
                                        {Object.keys(locations).map((campusName, index) => { return locations[campusName].map((location, index) => { return location.id }) }).flat().length === inspectionChecklistUsage.length ?
                                            <button className="backgroundLess" style={{ fontSize: 13 }} onClick={() => {
                                                setInspectionChecklistUsage([])
                                            }}>Unselect All</button> :
                                            <button className="backgroundLess" style={{ fontSize: 13 }} onClick={() => {
                                                setInspectionChecklistUsage(Object.keys(locations).map((campusName, index) => { return locations[campusName].map((location, index) => { return location.id }) }).flat())
                                            }}>Select All</button>}
                                        {Object.keys(locations).map((campusName, index) => { return locations[campusName].map((location, index) => { return location.id }) }).flat().length === firstAidChecklistUsage.length ?
                                            <button className="backgroundLess" style={{ fontSize: 13 }} onClick={() => {
                                                setFirstAidChecklistUsage([])
                                            }}>Unselect All</button> :
                                            <button className="backgroundLess" style={{ fontSize: 13 }} onClick={() => {
                                                setFirstAidChecklistUsage(Object.keys(locations).map((campusName, index) => { return locations[campusName].map((location, index) => { return location.id }) }).flat())
                                            }}>Select All</button>}
                                    </div>
                                </div>
                                {/* <pre>{JSON.stringify(inspectionChecklistUsage,null,2)}</pre> */}
                                {/* <pre>{JSON.stringify(locations,null,2)}</pre> */}
                                <div className='mt-40' style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "right", alignItems: "flex-end" }}>
                                    <p style={{ width: "100px", textAlign: "right" }}>Inspections</p>
                                    <p style={{ width: "100px", textAlign: "right" }}>First Aid</p>
                                </div>
                                {Object.keys(locations).map((campusName, index) => {
                                    return (
                                        <div className="mb-40">
                                            <p className="card-title">{campusName}</p>
                                            {locations[campusName].map((location, index) => {
                                                return (
                                                    <div className="row justifySplit alignCenter">
                                                        <p>{location.location_name}</p>
                                                        <div className="row gap-10 alignCenter">
                                                            <div style={{ width: 100, display: "flex", alignItems: 'flex-end', justifyContent: "right", alignContent: "flex-end" }} >
                                                                <input type="checkbox" checked={inspectionChecklistUsage?.includes(location.id)} onChange={(e) => {
                                                                    let temp = [...inspectionChecklistUsage]
                                                                    if (e.target.checked) {
                                                                        temp.push(location.id)
                                                                    } else {
                                                                        temp = temp.filter((id) => id != location.id)
                                                                    }
                                                                    setInspectionChecklistUsage(temp)
                                                                }} />
                                                            </div>
                                                            <div style={{ width: 100, display: "flex", alignItems: 'flex-end', justifyContent: "right", alignContent: "flex-end" }} >
                                                                <input type="checkbox" checked={firstAidChecklistUsage?.includes(location.id)} onChange={(e) => {
                                                                    let temp = [...firstAidChecklistUsage]
                                                                    if (e.target.checked) {
                                                                        temp.push(location.id)
                                                                    } else {
                                                                        temp = temp.filter((id) => id != location.id)
                                                                    }
                                                                    setFirstAidChecklistUsage(temp)
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </>}
                        </div>
                        <div className="card">
                            {/* <div className="input-container"> */}
                            <div className="row justifySplit alignCenter"
                            // style={{position:"sticky", top:headerTopPosition, backgroundColor:"white", zIndex:1, paddingTop:20}}
                            >
                                <p className="card-title">Checklist Items</p>
                                {/* <button className="secondary-button" onClick={() => {
                                        let temp = checklistDetails.checklist_items
                                        temp.push({ item_name: '', item_description: '', item_type: "radio", item_options: ["Yes", "No"], allow_remarks: 0 })
                                        setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                    }}>Add Checklist Item</button> */}
                                <button className="backgroundLess row gap-10 alignCenter" onClick={() => {
                                        setIsReorder(true)
                                }}>
                                    <MdReorder className="icon" size={20} color='black' />
                                    Reorder
                                </button>
                            </div>
                            <div className="input-box">
                                {checklistDetails.checklist_items.map((item, index) => {
                                    return (
                                        <div className="row alignStart">
                                            {/* <MdChevronRight className="icon" style={{marginTop:30}} size={50} color='black' /> */}
                                            <p style={{ marginTop: 0, fontSize: 80, color: "black" }}>•</p>
                                            <div className="card">
                                                <div className="row justifySplit alignCenter">
                                                    <p className='card-title'>Item {index + 1}</p>
                                                    <MdClose className="icon" size={30} color='black' onClick={() => {
                                                        let temp = checklistDetails.checklist_items
                                                        temp.splice(index, 1)
                                                        setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                                    }} />
                                                </div>

                                                <div className="row gap-10">
                                                    <div className="input-container">
                                                        <p className="input-label">Item Name</p>
                                                        <input className="text-input" type="text" value={item.item_name} onChange={(e) => {
                                                            let temp = checklistDetails.checklist_items
                                                            temp[index].item_name = e.target.value
                                                            setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                                        }} />
                                                    </div>
                                                    <div className="input-container">
                                                        <p className="input-label">Item Type</p>
                                                        <select className="select-input" value={item.item_type} onChange={(e) => {
                                                            let temp = checklistDetails.checklist_items
                                                            temp[index].item_type = e.target.value
                                                            setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                                        }}>
                                                            <option value="radio">Single Selection</option>
                                                            <option value="file">File Upload</option>
                                                            <option value="checkbox">Checkbox</option>
                                                            <option value="text">Text</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="input-container">
                                                    <p className="input-label">Allow Remarks</p>
                                                    <Switch
                                                        onChange={(e) => {
                                                            let temp = checklistDetails.checklist_items
                                                            temp[index].allow_remarks = e ? 1 : 0
                                                            setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                                        }}
                                                        checked={item.allow_remarks == 1}
                                                    />
                                                </div>

                                                <div className="input-container">
                                                    <p className="input-label">Item Description</p>
                                                    <textarea className="textarea-input" type="text" value={item.item_description} onChange={(e) => {
                                                        let temp = checklistDetails.checklist_items
                                                        temp[index].item_description = e.target.value
                                                        setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                                    }} />
                                                </div>

                                                {(item.item_type === "radio" || item.item_type === "checkbox") &&
                                                    <>
                                                        <p className="input-label">Item Options</p>
                                                        <div className="input-box">
                                                            {item.item_options.map((option, optionIndex) => {
                                                                return (
                                                                    <div className="input-container">
                                                                        <div className="row gap-10">
                                                                            <input className="text-input" type="text" value={option} onChange={(e) => {
                                                                                let temp = checklistDetails.checklist_items
                                                                                temp[index].item_options[optionIndex] = e.target.value
                                                                                setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                                                            }} />

                                                                            <button className="backgroundLess" onClick={() => {
                                                                                let temp = checklistDetails.checklist_items
                                                                                temp[index].item_options.splice(optionIndex, 1)
                                                                                setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                                                            }}>Delete Option</button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <button className="secondary-button" onClick={() => {
                                                                let temp = checklistDetails.checklist_items
                                                                temp[index].item_options.push("")
                                                                setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                                            }}>Add Option</button>
                                                        </div>
                                                    </>
                                                }

                                                {item.item_type === "file" &&
                                                    <>
                                                        {/* <div className="input-container">
                                                            <p className="input-label">File Type</p>
                                                        </div> */}
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    )
                                })}
                                {checklistDetails.checklist_items.length === 0 &&
                                    <p className="input-label">No Checklist Items added</p>
                                }
                                <div className='center alignCenter' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <button className="primary-button" style={{ marginTop: 10, }} onClick={() => {
                                        let temp = checklistDetails.checklist_items
                                        temp.push({ item_name: '', item_description: '', item_type: "radio", item_options: ["Yes", "No"], allow_remarks: 0 })
                                        setChecklistDetails({ ...checklistDetails, checklist_items: temp })
                                    }}>Add new item</button>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>

                {isMobile(width) && <div
                    style={{ backgroundColor: "white", padding: 20, position: "sticky", bottom: 0, zIndex: 50, boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
                >
                    <div className="column gap-10 alignCenter">
                        {isAuthorised && checklistDetails?.is_default != 1 && <button className='backgroundLess'
                            onClick={() => {
                                markAsDefault()
                            }}
                        >Mark as Default</button>}
                        {isAuthorised && checklistDetails?.is_default == 1 && <p>Default Checklist</p>}
                        {checklist_id !== "create" &&
                            <button className='delete-button' style={{ width: "100%" }}
                                onClick={() => {
                                    if (window.confirm("Are you sure you want to delete this checklist?")) {
                                        deleteChecklist()
                                    }
                                }}
                            >Delete</button>}

                        <button className='primary-button' style={{ width: "100%" }}
                            onClick={() => {
                                if (checklist_id === "create") {
                                    createChecklist()
                                } else {
                                    saveChecklist()
                                }
                            }}
                        >Save</button>
                    </div>
                </div>}
            </div>
        </div >
    )
}
