import React, { useState, useEffect, useLayoutEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import { tidyDate } from '../../utils/AppHelpers';
import { FaCross } from 'react-icons/fa6';
import { MdClose, MdUpload, MdUploadFile } from 'react-icons/md';
import { isMobile } from '../../utils/AppHelpers';

export default function AdminReportDetailPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const { report_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)
    const [reportDetails, setReportDetails] = useState({})
    const [points, setPoints] = useState(0)
    const [selectedMedia, setSelectedMedia] = useState({})

    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const fetchReportById = async () => {
        if (report_id === "create") return
        setIsLoading(true)
        let endpoint = config.endpoint + `/report/id/${report_id}`
        let response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setReportDetails(data.data)
            setPoints(data.data?.points)
        }
        setIsLoading(false)
    }

    const saveReport = async () => {
        if (report_id === "create") return toast.error("Cannot save a new report, please submit a new report instead")
        let endpoint = config.endpoint + `/report/id/${report_id}`
        let response = await fetch(endpoint, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...reportDetails,
                id: report_id,
                points: points || 0,
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Report saved successfully")
        } else {
            toast.error("Failed to save report")
        }
    }

    useEffect(() => {
        fetchReportById()
    }, [])

    useLayoutEffect(() => {
        if (isLoading) return
        saveReport()
    }, [reportDetails?.report_status])


    const [filesToUpload, setFilesToUpload] = useState([])

    // if the user drag over the file input, change the style
    useEffect(() => {
        let fileInput = document?.getElementById("file-input")
        let fileInputContainer = document?.getElementById("file-input-container")
        if (!fileInput || !fileInputContainer) return

        fileInput.addEventListener("dragover", (e) => {
            // add a class hover
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.add("hover")
        })
        fileInput.addEventListener("dragleave", (e) => {
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.remove("hover")
        })

        fileInput.addEventListener("drop", (e) => {
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.remove("hover")
            // get the files
            let files = e.dataTransfer.files
            // convert to array
            files = [...files]
            // check if the file is valid
            if (files.length > 0) {
                // set the file input to the files
                fileInput.files = files
                // call the function to upload the files
                setFilesToUpload(files)
            }
        })
    }, [])


    const submitDocuments = async () => {
        let formData = new FormData()
        for (let i = 0; i < filesToUpload.length; i++) {
            formData.append("files", filesToUpload[i])
        }
        formData.append("report_id", report_id)
        formData.append("case_type", "report")

        let endpoint = config.endpoint + "/evidence/"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            body: formData,
            credentials: "include"
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            // success
            toast.success(data.message)
            setFilesToUpload([])
            fetchReportById()
        } else {
            // error
            toast.error(data.message)
        }
    }


    const MediaModal = () => {
        return (
            <div className="modal" style={{ width: isMobile(width) && "100%", display: "flex", alignItems: isMobile(width) ? "flex-start" : "center", justifyContent: isMobile(width) ? "flex-end" : "center" }}>
                <div className="modal-content" style={{ width: isMobile(width) && "100%", height: isMobile(width) && "100%", }}>
                    <div className="modal-header row justifySplit  mb-10">
                        <p className="modal-title">Media Preview</p>
                        <button className="backgroundLess" onClick={() => {
                            setSelectedMedia({})
                        }}>
                            <MdClose color='black' size={20} />
                        </button>
                    </div>
                    <div className="modal-body center alignCenter row">
                        {(selectedMedia?.file_type?.includes("image") || selectedMedia?.file_type?.includes("text") || selectedMedia?.file_type?.includes("application")) &&
                            <img src={`${config.endpoint}${selectedMedia?.file_path}`.replace("./public", "")} style={{ maxHeight: isMobile(width) ? "50%" : 400, height: isMobile(width) && "50%", maxWidth: "100%", objectFit: "cover", borderRadius: 10 }} alt="" />}
                        {selectedMedia?.file_type?.includes("video") &&
                            <video style={{ maxHeight: 400, objectFit: "cover", borderRadius: 10 }} controls>
                                <source src={`${config.endpoint}${selectedMedia?.file_path}`.replace("./public", "")} type={selectedMedia?.file_type} />
                            </video>}
                        {selectedMedia?.file_type?.includes("audio") &&
                            <audio controls>
                                <source src={`${config.endpoint}${selectedMedia?.file_path}`.replace("./public", "")} type={selectedMedia?.file_type} />
                                Your browser does not support the audio element.
                            </audio>}
                    </div>
                    <a href={`${config.endpoint}${selectedMedia?.file_path}`.replace("./public", "")} download style={{ textDecoration: "none" }} id="downloadLink"></a>
                    <div className={`${isMobile(width) ? "column" : "row"} gap-10 center alignCenter mt-40`}>
                        <button className="primary-button" style={{ width: isMobile(width) && "100%" }} onClick={() => {
                            setSelectedMedia({})
                        }}>
                            Close
                        </button>
                        <button className="primary-button" style={{ width: isMobile(width) && "100%" }} onClick={() => {
                            document.getElementById("downloadLink").click()
                        }}>
                            Download
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const get_color = (type) => {
        let incident_color = "gray" // default
        if (type === "incident") {
            incident_color = "red"
        } else if (type === "suggestion") {
            incident_color = "orange"

        } else if (type === "compliment") {
            incident_color = "green"
        } else if (type === "firstaid") {
            incident_color = "blue"
        } else if (type === "inspection") {
            incident_color = "purple"
        }

        return incident_color
    }


    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Reports"} />
            {selectedMedia?.file_type && <MediaModal />}
            <div className='container'>
                <AdminHeader title={""} />
                <div className="component" style={{ position: "sticky", top: top, display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%", alignItems: "center" }}>
                    <div>
                        <p className="component-title">{reportDetails?.case_id ? reportDetails?.case_id : reportDetails?.id}</p>
                        <p className="component-subtitle">View Report</p>
                    </div>
                </div>
                <div className="inner-container" style={{ height: "100%" }}>
                    <div className={`${isMobile(width) ? "column" : "row"} gap-10 alignTop`} style={{ height: "100%" }}>
                        <div style={{ width: isMobile(width) ? "100%" : "60%", height: "100%" }} className='gap-10 flex column'>
                            <div className="card">
                                <p className="card-title">Report</p>
                                <div className="row gap-20">
                                    <p style={{ width: "100px" }}>Type</p>
                                    <div>
                                        <p
                                            style={{ backgroundColor: get_color(reportDetails?.type), padding: "5px 20px", fontSize: 13, color: "white", fontWeight: "bold" }}
                                        >{reportDetails?.type}</p>
                                    </div>
                                </div>
                                <div className="row gap-20 mt-10">
                                    <p style={{ width: "100px" }}>Date Time</p>
                                    <div>
                                        <p>{tidyDate(reportDetails?.createdAt || reportDetails?.reported_at)}</p>
                                    </div>
                                </div>
                                <div className="row gap-20 mt-10">
                                    <p style={{ width: "100px" }}>Location</p>
                                    <div>
                                        <p>{reportDetails?.location_data?.location_name} <span style={{ color: "gray", fontSize: 12 }}>({reportDetails?.location_data?.location_code})</span></p>
                                        <p className='mt-10'>{reportDetails?.campus_data?.campus_name}</p>
                                    </div>
                                </div>
                                <div className="row gap-20 mt-10">
                                    <p style={{ width: "100px" }}>Reporter</p>
                                    <div>
                                        <p>{reportDetails?.user_id ? `${reportDetails?.user_data?.username || reportDetails?.user_data?.email || reportDetails.reporter_name}` : `${reportDetails?.reporter_name || "Guest"}`}</p>
                                        <p style={{ fontSize: 13 }}>{reportDetails?.contact_information || "No contact information provided"}</p>
                                    </div>
                                </div>
                                <div className="input-container">
                                    <textarea className='textarea-input mt-10' value={reportDetails?.description} disabled />
                                </div>
                            </div>
                            <div className="card">
                                <p className="card-title">Internal Action</p>
                                <div className="row alignCenter center gap-10">
                                    <div className="input-container">
                                        <p className="input-label">Points</p>
                                        <input
                                            className='text-input'
                                            disabled={reportDetails?.report_status === "resolved" || reportDetails?.report_status === "dismissed"}
                                            type="number"
                                            value={points}
                                            onChange={(e) => {
                                                setPoints(e.target.value)
                                            }}
                                        />
                                    </div>

                                    <div className="input-container" style={{ width: "auto", minWidth: !isMobile(width) && 300 }}>
                                        <p className="input-label">Status</p>
                                        <select
                                            className='select-input'
                                            value={reportDetails?.report_status}
                                            style={{ width: "100%" }}
                                            onChange={(e) => {
                                                setReportDetails({
                                                    ...reportDetails,
                                                    report_status: e.target.value
                                                })
                                            }}
                                        >
                                            <option value={"open"}>Open</option>
                                            <option value={"pending"}>Pending</option>
                                            <option value={"resolved"}>Resolved</option>
                                            <option value={"dismissed"}>Dismissed</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="input-container ">
                                    <textarea className='textarea-input mt-10' placeholder='remarks' value={reportDetails?.internal_remarks} onChange={(e) => {
                                        setReportDetails({ ...reportDetails, internal_remarks: e.target.value })
                                    }} />
                                </div>

                                {/* Evidence */}
                                <div className="input-container" id='file-input'>
                                    <p className="input-label">Evidence File Upload</p>
                                    <input className="input" type="file" multiple accept="image/*,application/pdf,video/*,audio/*" style={{ display: "none" }} id='file-tag' onChange={(e) => {
                                        let files = e.target.files
                                        files = [...files]
                                        setFilesToUpload(files)
                                    }} />
                                    <div className="file-input-container cursor-pointer" id='file-input-container' style={{}} onClick={() => {
                                        document.getElementById("file-tag").click()
                                    }}>
                                        <MdUploadFile size={20} color={"black"} />
                                        <p className="file-input-label">{filesToUpload.length > 0 ? `${filesToUpload.length} files attached` : "Upload File(s)"}</p>
                                    </div>
                                    {filesToUpload.length > 0 &&
                                        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 10, marginTop: 10 }}>
                                            {filesToUpload.map((file, index) => {
                                                return (
                                                    <div style={{
                                                        maxWidth: 200,
                                                        backgroundColor: "gray",
                                                        display: "flex",
                                                        gap: 10,
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        padding: "5px 10px",
                                                        cursor: "pointer",
                                                        borderRadius: 5
                                                    }}
                                                        onClick={() => {
                                                            let newFilesToUpload = filesToUpload
                                                            newFilesToUpload.splice(index, 1)
                                                            setFilesToUpload([...newFilesToUpload])
                                                        }}
                                                    >
                                                        <p style={{
                                                            fontSize: 12,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            color: "white"
                                                        }}>{file.name}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    <div className="button-container justifyRight">
                                        <button className='backgroundLess row alignCenter gap-10'
                                            onClick={() => {
                                                submitDocuments()
                                            }}>
                                            <MdUpload size={20} />
                                            Upload
                                        </button>
                                    </div>

                                </div>


                                <div className="button-container justifyRight">
                                    <button className="primary-button" onClick={() => {
                                        submitDocuments()
                                    }}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: isMobile(width) ? "100%" : "40%", height: "100%" }}>
                            <div className="card">
                                <p className="card-title">Attached Files</p>
                                {reportDetails?.files_data?.length > 0 ? reportDetails?.files_data?.map((file, index) => {
                                    return (
                                        <div className="row gap-10 alignCenter mb-10"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectedMedia(file)
                                            }}
                                        >
                                            {(file?.file_type?.includes("image") || file?.file_type?.includes("text") || file?.file_type?.includes("application")) &&
                                                <img src={`${config.endpoint}${file?.file_path}`.replace("./public", "")} style={{ height: 50, width: 50, objectFit: "cover", borderRadius: 10 }} alt="" />}
                                            {file?.file_type?.includes("video") &&
                                                <video style={{ height: 50, width: 50, objectFit: "cover", borderRadius: 10 }}>
                                                    <source src={`${config.endpoint}${file?.file_path}`.replace("./public", "")} type={file?.file_type} />
                                                </video>}
                                            {file?.file_type?.includes("audio") &&
                                                <audio controls>
                                                    <source src={`${config.endpoint}${file?.file_path}`.replace("./public", "")} type={file?.file_type} />
                                                    Your browser does not support the audio element.
                                                </audio>}

                                            <p style={{ wordBreak: "break-word" }}>{file?.file_name}</p>
                                        </div>
                                    )
                                })
                                    : <p>No files attached</p>
                                }

                                <p className="card-title mt-20">Evidence Attached</p>
                                {reportDetails?.evidence_data?.length > 0 ? reportDetails?.evidence_data?.map((file, index) => {
                                    file = file?.media_data
                                    file["file_path"] = file["ext_file_path"]
                                    file["file_type"] = file["mime_type"]
                                    console.log(file)
                                    return (
                                        <div className="row gap-10 alignCenter mb-10"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectedMedia(file)
                                            }}
                                        >
                                            {(file?.file_type?.includes("image") || file?.file_type?.includes("text") || file?.file_type?.includes("application")) &&
                                                <img src={`${config.endpoint}${file?.file_path}`.replace("./public", "")} style={{ height: 50, width: 50, objectFit: "cover", borderRadius: 10 }} alt="" />}
                                            {file?.file_type?.includes("video") &&
                                                <video style={{ height: 50, width: 50, objectFit: "cover", borderRadius: 10 }}>
                                                    <source src={`${config.endpoint}${file?.file_path}`.replace("./public", "")} type={file?.file_type} />
                                                </video>}
                                            {file?.file_type?.includes("audio") &&
                                                <audio controls>
                                                    <source src={`${config.endpoint}${file?.file_path}`.replace("./public", "")} type={file?.file_type} />
                                                    Your browser does not support the audio element.
                                                </audio>}

                                            <p style={{ wordBreak: "break-word" }}>{file?.file_name}</p>
                                        </div>
                                    )
                                })
                                    : <p>No evidence files found</p>
                                }
                            </div>
                        </div>

                    </div>


                </div>
            </div>q
        </div>
    )
}
