import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import { isMobile, tidyDate } from '../../utils/AppHelpers';
import { MdClose } from 'react-icons/md';

export default function AdminInspectionDetailPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const { inspection_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)
    const [inspectionDetails, setInspectionDetails] = useState({})
    const [selectedMedia, setSelectedMedia] = useState({})
    const [width, setWidth] = useState(window.innerWidth)

    const [points, setPoints] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const fetchInspectionById = async () => {
        if (inspection_id === "create") return
        let endpoint = config.endpoint + `/inspection/id/${inspection_id}`
        let response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setInspectionDetails({
                ...data.data,
            })
            setPoints(data.data?.points)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchInspectionById()
    }, [])


    const saveInspection = async () => {
        if (!inspection_id) return
        if (isLoading) return
        let endpoint = config.endpoint + `/inspection/id/${inspection_id}`
        let response = await fetch(endpoint, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...inspectionDetails,
                points: points
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Successfully saved inspection")
        } else {
            toast.error("Failed to save inspection")
        }
    }

    useEffect(() => {
        saveInspection()
    }, [inspectionDetails?.inspection_status])


    const MediaModal = () => {
        return (
            <div className="modal" style={{ width: isMobile(width) && "100%", display: "flex", alignItems: isMobile(width) ? "flex-start" : "center", justifyContent: isMobile(width) ? "flex-end" : "center" }}>
                <div className="modal-content" style={{ width: isMobile(width) && "100%", height: isMobile(width) && "100%", }}>
                    <div className="modal-header row justifySplit mb-10">
                        <p className="modal-title">Media Preview</p>
                        <button className="backgroundLess" onClick={() => {
                            setSelectedMedia({})
                        }}>
                            <MdClose color='black' size={20} />
                        </button>
                    </div>
                    <div className="modal-body center alignCenter row">
                        {(selectedMedia?.file_type?.includes("image") || selectedMedia?.file_type?.includes("text") || selectedMedia?.file_type?.includes("application")) &&
                            <img src={`${config.endpoint}${selectedMedia?.file_path}`.replace("./public", "")} style={{ maxHeight: isMobile(width) ? "50%" : 400, height: isMobile(width) && "50%", maxWidth: "100%", objectFit: "cover", borderRadius: 10 }} alt="" />}
                        {selectedMedia?.file_type?.includes("video") &&
                            <video style={{ maxHeight: 400, objectFit: "cover", borderRadius: 10 }} controls>
                                <source src={`${config.endpoint}${selectedMedia?.file_path}`.replace("./public", "")} type={selectedMedia?.file_type} />
                            </video>}
                        {selectedMedia?.file_type?.includes("audio") &&
                            <audio controls>
                                <source src={`${config.endpoint}${selectedMedia?.file_path}`.replace("./public", "")} type={selectedMedia?.file_type} />
                                Your browser does not support the audio element.
                            </audio>}
                    </div>
                    <a href={`${config.endpoint}${selectedMedia?.file_path}`.replace("./public", "")} download style={{ textDecoration: "none" }} id="downloadLink"></a>
                    <div className={`${isMobile(width) ? "column" : "row"} gap-10 center alignCenter mt-40`}>
                        <button className="primary-button" style={{ width: isMobile(width) && "100%" }} onClick={() => {
                            setSelectedMedia({})
                        }}>
                            Close
                        </button>
                        <button className="primary-button" style={{ width: isMobile(width) && "100%" }} onClick={() => {
                            document.getElementById("downloadLink").click()
                        }}>
                            Download
                        </button>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Reports"} />
            {selectedMedia?.file_type && <MediaModal />}
            <div className='container'>
                <AdminHeader title={""} />
                <div className="component alignCenter" style={{ position: "sticky", top: top, display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%", alignItems: "center" }}>
                    <div>
                        <p className="component-title">Inspection #{inspectionDetails?.case_id || inspectionDetails?.id}</p>
                        <p className="component-subtitle">View Inspections</p>
                    </div>
                    {/* <div className="input-container" style={{ width: "auto" }}>
                        <select
                            className='select-input'
                            value={inspectionDetails?.inspection_status}
                            style={{ width: "fit-content" }}
                            onChange={(e) => {
                                setInspectionDetails({
                                    ...inspectionDetails,
                                    inspection_status: e.target.value
                                })
                            }}
                        >
                            <option value={"open"}>Open</option>
                            <option value={"approved"}>Approved</option>
                            <option value={"rejected"}>Rejected</option>
                        </select>
                    </div> */}
                </div>
                <div className="inner-container" style={{ height: "100%" }}>
                    {/* <pre>{JSON.stringify(inspectionDetails, null, 2)}</pre> */}
                    <div className={`${isMobile(width) ? "column" : "row"} gap-10 alignTop`} style={{ height: "100%" }}>
                        <div style={{ width: !isMobile(width) ? "60%" : "100%", height: "100%" }} className='gap-10 flex column'>
                            <div className="card">
                                <p className="card-title">Inspection</p>
                                <div className="row gap-20 mt-10">
                                    <p style={{ width: "100px" }}>Date Time</p>
                                    <div>
                                        <p>{tidyDate(inspectionDetails?.reported_at || inspectionDetails?.createdAt)}</p>
                                    </div>
                                </div>
                                <div className="row gap-20 mt-10">
                                    <p style={{ width: "100px" }}>Location</p>
                                    <div>
                                        <p>{inspectionDetails?.location_data?.location_name}</p>
                                        <p className='mt-10'>{inspectionDetails?.campus_data?.campus_name}</p>
                                    </div>
                                </div>
                                <div className="row gap-20 mt-10">
                                    <p style={{ width: "100px" }}>User</p>
                                    {/* <pre>{JSON.stringify(inspectionDetails?.staff_data,null,2)}</pre> */}
                                    <div>
                                        <p>{inspectionDetails?.staff_id ? `${inspectionDetails?.staff_data?.username || inspectionDetails?.staff_data?.email}` : "Guest"}</p>
                                    </div>
                                </div>
                                <div className="input-container">
                                    <p className='mt-10'>Remarks</p>
                                    <textarea className='textarea-input mt-10' value={inspectionDetails?.inspection_remarks} disabled />
                                </div>
                            </div>
                            <div className="card">
                                <div className="input-container">
                                    <p className="card-title">Internal Action</p>

                                    <div className="row alignCenter center gap-10">
                                        <div className="input-container">
                                            <p className="input-label">Points</p>
                                            <input
                                                className='text-input'
                                                disabled={inspectionDetails?.inspection_status === "approved" || inspectionDetails?.inspection_status === "rejected"}
                                                type="number"
                                                value={points}
                                                onChange={(e) => {
                                                    setPoints(e.target.value)
                                                }}
                                            />
                                        </div>

                                        <div className="input-container" style={{ width: "auto", minWidth: !isMobile(width) && 300 }}>
                                            <p className="input-label">Status</p>
                                            <select
                                                className='select-input'
                                                value={inspectionDetails?.inspection_status}
                                                style={{ width: "100%" }}
                                                onChange={(e) => {
                                                    setInspectionDetails({
                                                        ...inspectionDetails,
                                                        inspection_status: e.target.value
                                                    })
                                                }}
                                            >
                                                <option value={"open"}>Open</option>
                                                <option value={"pending"}>Pending</option>
                                                <option value={"resolved"}>Resolved</option>
                                                <option value={"dismissed"}>Dismissed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <textarea className='textarea-input mt-10' placeholder='remarks' value={inspectionDetails?.internal_remarks} onChange={(e) => {
                                        setInspectionDetails({ ...inspectionDetails, internal_remarks: e.target.value })
                                    }} />
                                </div>
                                <div className="button-container justifyRight" style={{}}>
                                    <button className="primary-button" onClick={() => {
                                        saveInspection()
                                    }}
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: !isMobile(width) ? "40%" : "100%", height: "100%" }}>
                            <div className="card">
                                <p className="card-title">Submitted Report</p>
                                {inspectionDetails?.inspection_response?.map((item, index) => {
                                    return (
                                        <div className="card" key={index} style={{ marginBottom: 10 }}>
                                            <p className="card-title">{index + 1}. {item?.item_name}</p>
                                            <p style={{ color: "gray" }}>{item?.item_description}</p>
                                            <div className="row gap-10">
                                                {item?.item_type === "text" && <textarea className="textarea-input" disabled value={item?.item_value} />}
                                                {item?.item_type === "checkbox" && <div>
                                                    {item?.item_options?.map((option, index1) => {
                                                        return (
                                                            <div className="row gap-10 mt-40" key={index1}>
                                                                <input type="checkbox" checked={item?.selection?.[index1]} />
                                                                <p>{option}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                }
                                                {item?.item_type === "radio" && <div>
                                                    {item?.item_options?.map((option, index1) => {
                                                        return (
                                                            <div className="row gap-10 mt-40" key={index1}>
                                                                <input type="radio" checked={item?.selection === option} />
                                                                <p>{option}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                }
                                                {item?.item_type === "file" && <div>
                                                    {item?.item_value?.map((file, index2) => {
                                                        return (
                                                            <div className="row gap-10 mb-10"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setSelectedMedia(file)
                                                                }}
                                                            >
                                                                {(file?.file_type?.includes("image") || file?.file_type?.includes("text") || file?.file_type?.includes("application")) &&
                                                                    <img src={`${config.endpoint}${file?.file_path}`.replace("./public", "")} style={{ height: 50, width: 50, objectFit: "cover", borderRadius: 10 }} alt="" />}
                                                                {file?.file_type?.includes("video") &&
                                                                    <video style={{ height: 50, width: 50, objectFit: "cover", borderRadius: 10 }}>
                                                                        <source src={`${config.endpoint}${file?.file_path}`.replace("./public", "")} type={file?.file_type} />
                                                                    </video>}
                                                                {file?.file_type?.includes("audio") &&
                                                                    <audio controls>
                                                                        <source src={`${config.endpoint}${file?.file_path}`.replace("./public", "")} type={file?.file_type} />
                                                                        Your browser does not support the audio element.
                                                                    </audio>}
                                                                <p style={{ wordBreak: "break-word" }}>{file?.file_name}</p>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                }
                                            </div>
                                            {item?.allow_remarks && <div className="input-container mt-20">
                                                <p className="input-label">Remarks</p>
                                                <textarea className="textarea-input" placeholder="Enter remarks here" value={item?.remarks} disabled />
                                            </div>}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
