import React, { useEffect, useLayoutEffect, useState } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Bar, Line, } from 'react-chartjs-2'
// import {
//     Chart as ChartJS, ArcElement, Tooltip, Legend,
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
// } from "chart.js";

import Chart from 'chart.js/auto'
import { Doughnut } from 'react-chartjs-2';
import config from '../../app-config';
import { useFetchAuthorisation, useFetchUser } from '../../utils/AppFunctions';
import { capitalised, isMobile, tidyDate } from '../../utils/AppHelpers';
// Chart.register(ArcElement, Tooltip, Legend, CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend,
//     CategoryScale,
// );
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import notify from '../../utils/IANotification';
import { MdCalendarMonth, MdClose, MdFilterNone } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';
import Select from 'react-select';



// Set the Y axis to start from 0 and to have a whole number scale
const graphOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            align: "start"
        },
        title: {
            display: true,
            // text: 'Inspections',
        },
        datalabels: {
            // display: false,
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
        y: {
            beginAtZero: true,  // Ensure Y axis starts from 0
            stepSize: 2,
            grid: {
                display: false,
            },
            ticks: {
                precision: 0
            }
        },
    },
};
// const graphOptions = {
//     responsive: true,
//     scales: {
//         yAxes: [{
//             ticks: {
//                 beginAtZero: true,
//                 precision: 0
//             }
//         }],
//         x: {
//             grid: {
//                 display: false,
//             },
//         },
//         y: {
//             grid: {
//                 display: false,
//             },
//         },
//     },
// };

const datetime = () => {
    let dateObj = new Date()
    let hours = dateObj.getHours()
    let minutes = dateObj.getMinutes()
    let seconds = dateObj.getSeconds()
    let day = dateObj.getDay()
    let date = dateObj.getDate()
    let month = dateObj.getMonth()
    let year = dateObj.getFullYear()
    const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    return { date: `${date} ${MONTHS[month].slice(0, 3)}`, time: `${hours}:${minutes}:${seconds}`, day: DAYS[day], month, year }
}


export default function AdminHomePage() {
    const [width, setWidth] = useState(window.innerWidth);

    const navigate = useNavigate()
    const [isLive, setIsLive] = useState(true)
    const [reportData, setReportData] = useState([])
    const [reportBreakdownData, setReportBreakdownData] = useState([])
    const [inspectionData, setInspectionData] = useState([])
    const [timeSelected, setTimeSelected] = useState("month") // day, week, month, year

    const [reportsChartData, setReportsChartData] = useState({})
    const [reportsBreakdownChartData, setReportsBreakdownChartData] = useState()
    const [inspectionsChartData, setInspectionsChartData] = useState({})

    const [dateString, setDateString] = useState(`${datetime().date} ${datetime().time}`)

    const [inspectionApiData, setInspectionApiData] = useState([])
    const [reportApiData, setReportApiData] = useState([])
    const [updatedAt, setUpdatedAt] = useState("")
    const [quickStats, setQuickStats] = useState({})
    const [platformUsage, setPlatformUsage] = useState({})

    const [locationData, setLocationData] = useState([])
    const [campusData, setCampusData] = useState([])


    // Filters
    const [startDateRange, setStartDateRange] = useState()
    const [endDateRange, setEndDateRange] = useState()
    const [locationFilter, setLocationFilter] = useState("")
    const [campusFilter, setCampusFilter] = useState("")
    const [showDateRange, setShowDateRange] = useState(false)
    const [dateRange, setDateRange] = useState(document.getElementById("date-range")?.getBoundingClientRect())
    const [typeSelected, setTypeSelected] = useState("") // incident, suggestion, compliment, firstaid, inspection
    const [statusSelected, setStatusSelected] = useState("") // open, pending, resolved, dismissed

    const APPROVED_COLOURS = ["blue", "green", "yellow", "red", "purple", "orange", "pink", "brown", "gray", "black", "white", "cyan", "magenta", "lime", "teal", "indigo", "maroon", "navy", "olive", "silver", "aqua", "fuchsia", "limegreen", "crimson", "coral", "gold", "khaki", "lavender", "orchid", "plum", "salmon", "tan", "turquoise", "violet", "wheat", "aliceblue", "antiquewhite", "aquamarine", "azure", "beige", "bisque", "blanchedalmond", "blueviolet", "burlywood", "cadetblue", "chartreuse", "chocolate", "coral", "cornflowerblue", "cornsilk", "darkblue", "darkcyan", "darkgoldenrod", "darkgray", "darkgreen", "darkgrey", "darkkhaki", "darkmagenta", "darkolivegreen", "darkorange", "darkorchid", "darkred", "darksalmon", "darkseagreen", "darkslateblue", "darkslategray", "darkslategrey", "darkturquoise", "darkviolet", "deeppink", "deepskyblue", "dimgray", "dimgrey", "dodgerblue", "firebrick", "floralwhite", "forestgreen", "gainsboro", "ghostwhite", "goldenrod", "greenyellow", "grey", "honeydew", "hotpink", "indianred", "ivory", "khaki", "lavenderblush", "lawngreen", "lemonchiffon", "lightblue", "lightcoral", "lightcyan", "lightgoldenrodyellow", "lightgray", "lightgreen", "lightgrey", "lightpink", "lightsalmon", "lightseagreen", "lightskyblue", "lightslategray", "lightslategrey", "lightsteelblue", "lightyellow", "limegreen", "linen", "magenta", "mediumaquamarine", "mediumblue", "mediumorchid", "mediumpurple", "mediumseagreen", "mediumslateblue",]

    let user_data = useFetchUser()

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, [])

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
        setDateRange(document.getElementById("date-range")?.getBoundingClientRect())
    })

    window.addEventListener("scroll", () => {
        setDateRange(document.getElementById("date-range")?.getBoundingClientRect())
        setShowDateRange(false)
    })


    // useEffect(() => {
    //     let interval = setInterval(() => {
    //         if (!user_data) {
    //             console.log(user_data)
    //             navigate("/auth/login")
    //         }
    //     },3000)
    //     return () => {
    //         clearInterval(interval)
    //     }
    // }, [])


    let timeSelectedData = {
        "day": "Day",
        "week": "Week",
        "month": "Month",
        "year": "Year"
    }


    useEffect(() => {
        console.log({ startDateRange, endDateRange, locationFilter, campusFilter, typeSelected, statusSelected })
        if (startDateRange || endDateRange || locationFilter || campusFilter || typeSelected || statusSelected) {
            setIsLive(false)
        }
    }, [startDateRange, endDateRange, locationFilter, campusFilter, typeSelected, statusSelected])

    const fetchReports = async () => {
        let endpoint = config.endpoint + `/report?` + (startDateRange ? `start_date=${startDateRange}&` : "") + (endDateRange ? `end_date=${endDateRange}&` : "") + (locationFilter ? `location_id=${locationFilter}&` : "") + (campusFilter ? `campus_id=${campusFilter}&` : "") + (typeSelected ? `type=${typeSelected}&` : "") + (statusSelected ? `status=${statusSelected}&` : "")
        let fetchResponse = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {

            setReportApiData(response?.data?.reverse())
        }
    }

    const fetchLocations = async () => {
        let endpoint = config.endpoint + "/location"
        let fetchResponse = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            setLocationData(response?.data)
        }
    }

    const fetchCampuses = async () => {
        let endpoint = config.endpoint + "/campus"
        let fetchResponse = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            setCampusData(response?.data)
        }
    }

    const fetchReportAnalytics = async () => {
        let endpoint = config.endpoint + "/analytics/reports?" + (startDateRange ? `start_date=${startDateRange}&` : "") + (endDateRange ? `end_date=${endDateRange}&` : "") + (locationFilter ? `location_id=${locationFilter}&` : "") + (campusFilter ? `campus_id=${campusFilter}&` : "") + (typeSelected ? `type=${typeSelected}&` : "") + (statusSelected ? `status=${statusSelected}&` : "")
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            if (Object.keys(response.data)?.length === 0) {
                return
            }
            let labels = [...Object.keys(response.data[timeSelected])]
            setReportData(response.data)
            setUpdatedAt(tidyDate(new Date().toISOString()))

            let temp = response.data[timeSelected]
            let types = ["incident", "suggestion", "compliment", "firstaid", "inspection", "all"]
            let tempData = {}
            // if (labels?.slice(-10)?.length === 0) {
            //     return
            // }
            // if (!labels?.slice(-10)) {
            //     return
            // }

            // console.log({ label: labels?.slice(-10) })

            // Donut
            setReportsBreakdownChartData(
                {
                    labels: Object.keys(response?.breakdown),
                    datasets: [
                        {
                            label: 'Reports',
                            data: Object.values(response?.breakdown),
                            backgroundColor: [
                                ...Object.keys(response?.breakdown)?.map((item, index) => {
                                    return APPROVED_COLOURS[index]
                                })
                            ]
                        }
                    ]
                }
            )

            types.forEach(type => {
                tempData[type] = {
                    labels: labels.slice(-10),
                    datasets: [
                        {
                            label: typeSelected ? capitalised(typeSelected) : "All Reports",
                            data: [...Object.values(temp)?.map((item) => {
                                let count = 0
                                item.forEach(report => {
                                    console.log(report?.type)
                                    if (typeSelected === "all" || !typeSelected) {
                                        count++
                                    } else {
                                        if ((report.type)?.toLowerCase().replaceAll(" ", "").replace("-", "") === typeSelected?.toLowerCase().replaceAll(" ", "").replace("-", "")) {
                                            count++
                                        }
                                    }
                                    // if (typeSelected === "all") {
                                    //     count++
                                    // }
                                })
                                return count
                            })].slice(-10),
                        }
                    ]
                }
            })

            console.log({ tempData })

            setReportsChartData(
                tempData
            )
        }
    }


    const fetchQuickStats = async () => {
        let endpoint = config.endpoint + "/analytics/system"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            setQuickStats(response.data)
        }
    }

    const fetchPlatformUsageStats = async () => {
        let endpoint = config.endpoint + "/analytics/platform-usage?" + (startDateRange ? `start_date=${startDateRange}&` : "") + (endDateRange ? `end_date=${endDateRange}&` : "") + (locationFilter ? `location_id=${locationFilter}&` : "") + (campusFilter ? `campus_id=${campusFilter}&` : "") + (typeSelected ? `type=${typeSelected}&` : "") + (statusSelected ? `status=${statusSelected}&` : "")
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            setPlatformUsage(response.data)
        }
    }

    useEffect(() => {
        let interval = setInterval(() => {
            setDateString(`${datetime().day}, ${datetime().date} ${datetime().year}`)
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        if (startDateRange && endDateRange) {
            fetchReports()
            fetchReportAnalytics()
            fetchPlatformUsageStats()
        }
    }, [startDateRange, endDateRange])

    useEffect(() => {
        fetchReports()
        fetchReportAnalytics()
        fetchPlatformUsageStats()
    }, [locationFilter, campusFilter, typeSelected, statusSelected])

    useEffect(() => {
        fetchQuickStats()
        fetchCampuses()
        fetchLocations()
        fetchReportAnalytics()
        fetchPlatformUsageStats()
    }, [])


    useEffect(() => {
        // fetchReportAnalytics()
        // fetchQuickStats()
        // fetchPlatformUsageStats()
        // fetchLocations()
        // fetchCampuses()
        // fetchReports()

        if (!isLive) return
        let interval = setInterval(() => {
            setDateString(`${datetime().day}, ${datetime().date} ${datetime().year}`)
            fetchReportAnalytics()
            fetchQuickStats()
            fetchLocations()
            fetchCampuses()
            fetchPlatformUsageStats()
            fetchReports()
        }, 60000)
        return () => {
            clearInterval(interval)
        }
    }, [isLive])

    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Dashboard"} />
            <div className='container'>
                <AdminHeader title={"Overview"} subtitle={"Welcome back!"} />
                <div className="row w-100 right" style={{ width: "100%", marginTop: 10, marginRight: 10, paddingRight: 10, paddingLeft: 10, display: "flex", justifyContent: "space-between" }}>
                    <div className='row alignCenter gap-10' style={{ backgroundColor: isLive ? "yellowgreen" : "pink", padding: 5, borderRadius: 20, paddingLeft: 5, paddingRight: 10 }}>
                        <div style={{ height: 20, width: 20, borderRadius: "50%", backgroundColor: isLive ? "yellow" : "purple" }}></div>
                        <p style={{ color: isLive ? "green" : "red", fontSize: 12 }}>{isLive ? "Live" : "Paused"}</p>
                    </div>
                    <p style={{ fontSize: 12, }}>Last updated: {updatedAt}</p>
                </div>
                <div className='p-all' style={{}}>
                    <p className='pt-10' style={{ fontWeight: "600", fontSize: 20, }}>Hello {user_data?.username || "Admin"},</p>
                    <p style={{ marginTop: 10 }}>It's {dateString}</p>
                </div>



                <div className="inner-container">
                    <div style={{ height: "100%" }}>
                        {/* Quick Stats */}
                        <div className={`${isMobile(width) ? "row" : "row"} mb-10`} style={{ display: "flex", overflowY: "scroll" }}>
                            <div className="card mr-10 row alignCenter" style={{ width: "auto", marginBottom: 0, paddingBottom: 10 }}>
                                <p className="card-title" style={{ fontSize: 30, marginRight: 20, fontWeight: 300 }}>{quickStats?.reports || "-"}</p>
                                <p style={{ marginBottom: 0, paddingBottom: 0 }}><span style={{ fontWeight: "bold", color: "gray" }}>Reports till date</span></p>
                            </div>
                            <div className="card mr-10 row alignCenter" style={{ width: "auto", marginBottom: 0, paddingBottom: 10 }}>
                                <p className="card-title" style={{ fontSize: 30, marginRight: 20, fontWeight: 300 }}>{quickStats?.users || "-"}</p>
                                <p style={{ marginBottom: 0, paddingBottom: 0 }}><span style={{ fontWeight: "bold", color: "gray" }}>Users</span></p>
                            </div>
                            <div className="card mr-10 row alignCenter" style={{ width: "auto", marginBottom: 0, paddingBottom: 10 }}>
                                <p className="card-title" style={{ fontSize: 30, marginRight: 20, fontWeight: 300 }}>{quickStats?.campus || "-"}</p>
                                <p style={{ marginBottom: 0, paddingBottom: 0 }}><span style={{ fontWeight: "bold", color: "gray" }}>Campus</span></p>
                            </div>
                            <div className="card mr-10 row alignCenter" style={{ width: "auto", marginBottom: 0, paddingBottom: 10 }}>
                                <p className="card-title" style={{ fontSize: 30, marginRight: 20, fontWeight: 300 }}>{quickStats?.locations || "-"}</p>
                                <p style={{ marginBottom: 0, paddingBottom: 0 }}><span style={{ fontWeight: "bold", color: "gray" }}>Locations</span></p>
                            </div>
                        </div>


                        {/* Filters */}

                        <div className={`${isMobile(width) ? "column" : "row"} gap-10 mt-10 mb-10`} style={{ display: "flex", flexWrap: "wrap" }}>
                            {/* <div className={`${isMobile(width) ? "row" : "row"} gap-10`} style={{ display: "flex", flexWrap: "wrap" }}> */}
                            <div className="row gap-10">
                                <Select
                                    value={{
                                        value: campusFilter,
                                        label: campusData?.find((campus) => campus.id === campusFilter)?.campus_name || "All Campuses"
                                    }}
                                    onChange={(e) => {
                                        setCampusFilter(e.value)
                                    }}
                                    options={[
                                        { value: "", label: "All Campuses" },
                                        ...campusData?.map((campus, index) => {
                                            return {
                                                value: campus?.id,
                                                label: campus?.campus_name
                                            }
                                        })]}
                                    styles={{
                                        menuList: (provided) => ({
                                            ...provided,
                                            color: "black"
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                    }}
                                />
                                <Select
                                    value={{
                                        value: locationFilter,
                                        label: locationData?.find((location) => location.id === locationFilter)?.location_name || "All Locations"
                                    }}
                                    onChange={(e) => {
                                        setLocationFilter(e.value)
                                    }}
                                    options={[
                                        { value: "", label: "All Locations" },
                                        ...locationData?.map((location, index) => {
                                            return {
                                                value: location?.id,
                                                label: `[${location?.location_code}] ${location?.location_name}`
                                            }
                                        })]}
                                    styles={{
                                        menuList: (provided) => ({
                                            ...provided,
                                            color: "black"
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: 200,
                                        }),
                                    }}
                                />
                                <Select
                                    value={{
                                        value: typeSelected,
                                        label: capitalised(typeSelected ? typeSelected : "All Reports")
                                    }}
                                    onChange={(e) => {
                                        setTypeSelected(e.value)
                                    }}
                                    options={[
                                        { value: "", label: "All Reports" },
                                        { value: "incident", label: "Incident" },
                                        { value: "suggestion", label: "Suggestion" },
                                        { value: "compliment", label: "Compliment" },
                                        { value: "firstaid", label: "First Aid" },
                                        { value: "inspection", label: "Inspection" },
                                    ]}
                                    styles={{
                                        menuList: (provided) => ({
                                            ...provided,
                                            color: "black"
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                    }}
                                />
                                <Select
                                    value={{
                                        value: statusSelected,
                                        label: capitalised(statusSelected ? statusSelected : "All Status")
                                    }}
                                    onChange={(e) => {
                                        setStatusSelected(e.value)
                                    }}
                                    options={[
                                        { value: "", label: "All Status" },
                                        { value: "open", label: "Open" },
                                        { value: "pending", label: "Pending" },
                                        { value: "resolved", label: "Resolved" },
                                        { value: "dismissed", label: "Dismissed" },
                                    ]}
                                    styles={{
                                        menuList: (provided) => ({
                                            ...provided,
                                            color: "black"
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                    }}
                                />


                            </div>
                            <div className="row gap-10 alignCenter text-input" style={{ backgroundColor: "white", fontSize: 13, fontWeight: "normal", width: "auto" }} id="date-range">
                                <p style={{ margin: 0, padding: 0, whiteSpace: "nowrap" }}>{startDateRange ? tidyDate(startDateRange) : "Start Date"}</p>
                                <MdCalendarMonth style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setShowDateRange(!showDateRange)} color='black' size={20} />
                            </div>
                            <div className="row gap-10 alignCenter text-input" style={{ backgroundColor: "white", fontSize: 13, fontWeight: "normal", width: "auto" }}>
                                <p style={{ margin: 0, padding: 0, whiteSpace: "nowrap" }}>{endDateRange ? tidyDate(endDateRange) : "End Date"}</p>
                                <MdCalendarMonth style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setShowDateRange(!showDateRange)} color='black' size={20} />
                            </div>
                            {showDateRange &&
                                <div
                                    style={{ position: "absolute", zIndex: 1000, top: dateRange?.top + 50, left: dateRange?.left, }}
                                >
                                    <DateRangePicker
                                        ranges={[
                                            {
                                                startDate: new Date(startDateRange || Date.now()),
                                                endDate: new Date(endDateRange || Date.now()),
                                                key: 'selection',
                                            }
                                        ]}

                                        showPreview={false}
                                        showDateDisplay={false}
                                        direction='horizontal'
                                        maxDate={new Date(Date.now())}
                                        months={2}
                                        onChange={(item) => {
                                            setStartDateRange(new Date(item.selection.startDate).toISOString())
                                            setEndDateRange(new Date(item.selection.endDate).toISOString())
                                        }}

                                    />
                                </div>
                            }
                            {showDateRange && <div className="row alignCenter gap-10 cursor-pointer"
                                onClick={() => {
                                    setShowDateRange(false)
                                }}
                            >
                                <p>Close Calendar</p>
                            </div>}

                            {startDateRange || endDateRange || locationFilter || campusFilter ?
                                <div className="row alignCenter gap-10 cursor-pointer"
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={() => {
                                        setStartDateRange("")
                                        setEndDateRange("")
                                        setLocationFilter("")
                                        setCampusFilter("")
                                        setTypeSelected("")
                                        setStatusSelected("")
                                        setIsLive(true)
                                    }}
                                >
                                    <p>Clear Filter</p>
                                </div> : null}
                            {/* </div> */}
                        </div>

                        {!isMobile(width) && <div className={`${isMobile(width) ? "column" : "row"} gap-10`} style={{ display: "flex", height: 400, overflow: "scroll" }}>
                            <div className="card">
                                <p className="card-title">{typeSelected ? capitalised(typeSelected) : "All Reports"}</p>
                                {Object.keys(reportsChartData)?.length > 0 && reportsChartData && <Line style={{ width: "100%", height: 290, objectFit: "contain" }} options={graphOptions} data={reportsChartData[typeSelected || "all"]} />}
                                {/* <pre>{JSON.stringify(reportsChartData, null, 2)}</pre> */}
                            </div>

                            <div className="card" style={{ width: "100%" }}>
                                <p className="card-title">Platforms</p>
                                {Object.keys(platformUsage)?.length > 0 && <Bar style={{ width: "100%", }} options={{
                                    indexAxis: 'y',
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'bottom',
                                            align: "start"
                                        },
                                        title: {
                                            display: true,
                                        },
                                        datalabels: {
                                            display: true,
                                        },
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                            ticks: {
                                                precision: 0
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,  // Ensure Y axis starts from 0
                                            stepSize: 2,
                                            grid: {
                                                display: false,
                                            },
                                            ticks: {
                                                precision: 0
                                            },
                                        },
                                    },
                                }} data={{
                                    labels: ["Platform Usage"],
                                    datasets: [
                                        ...Object.keys(platformUsage)?.map((item) => {
                                            return {
                                                label: capitalised(item),
                                                data: [platformUsage[item]],
                                                backgroundColor: [ // Random colours
                                                    ...Array.from({ length: Object.keys(platformUsage)?.length }, () => {
                                                        return APPROVED_COLOURS[Math.floor(Math.random() * APPROVED_COLOURS.length)]
                                                    })
                                                    // ...Object.keys(platformUsage)?.map((item, index) => { 
                                                    //     return APPROVED_COLOURS[index]
                                                    // })
                                                ]
                                            }
                                        })
                                        // {
                                        //     label: 'Platform Usage',
                                        //     data: Object.values(platformUsage)?.map((item) => item),
                                        //     backgroundColor: [ // Random colours
                                        //         ...Array.from({ length: Object.keys(platformUsage)?.length }, () => {
                                        //             return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`
                                        //         })
                                        //     ]
                                        // }
                                    ]
                                }} />}

                            </div>
                            <div className="card" style={{}} id='donut'>
                                <p className="card-title">Breakdown</p>
                                {Object.keys(reportsBreakdownChartData || []).length > 0 && <Doughnut style={{ width: "100%", height: 290, objectFit: "contain" }} data={reportsBreakdownChartData || []} options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'bottom',
                                            align: "start"
                                        },
                                        title: {
                                            display: true,
                                        },
                                        datalabels: {
                                            display: true,
                                        },
                                    },
                                }} />}
                            </div>
                        </div>}

                        {/* Reports */}
                        <div className={`${isMobile(width) ? "column" : "row"} gap-10 mt-10`}>
                            <div className="card">
                                <p className="card-title">{startDateRange && endDateRange ? `Reports from ${tidyDate(startDateRange)} to ${tidyDate(endDateRange)}` : "Reports"}</p>
                                {reportApiData?.length > 0 ? reportApiData?.map((report, index) => {

                                    let color = "gray" // default
                                    if (report.report_status === "open") {
                                        color = "red"
                                    } else if (report.report_status === "pending") {
                                        color = "orange"
                                    } else if (report.report_status === "resolved") {
                                        color = "green"
                                    }

                                    let incident_color = "gray" // default
                                    if (report.type === "incident") {
                                        incident_color = "red"
                                    } else if (report.type === "suggestion") {
                                        incident_color = "orange"

                                    } else if (report.type === "compliment") {
                                        incident_color = "green"

                                    } else if (report.type === "firstaid") {
                                        incident_color = "blue"
                                    } else if (report.type === "inspection") {
                                        incident_color = "purple"
                                    }

                                    return (
                                        <div className="card mb-10 cursor-pointer"
                                            onClick={() => {
                                                if (report.type === "incident" || report.type === "suggestion" || report.type === "compliment" || report.type === "report") {
                                                    navigate(`/admin/reports/id/${report?.id}`)
                                                } else if (report.type === "firstaid") {
                                                    navigate(`/admin/first-aid/id/${report?.id}`)
                                                } else if (report.type === "inspection") {
                                                    navigate(`/admin/inspections/id/${report?.id}`)
                                                }
                                            }}
                                        >
                                            <div className={`${isMobile(width) ? "column" : "row"} alignCenter justifySplit`} style={{ display: "flex", alignItems: "center" }}>
                                                <div>
                                                    <p className="card-title" style={{ margin: 0, padding: 0 }}>{report?.case_id} - {tidyDate(report?.reported_at || report?.createdAt)}</p>
                                                    <p className="card-title" style={{ color: "gray", padding: 0, marginBottom: isMobile(width) ? 10 : 0 }}>{report?.location_data?.location_name} @ {report?.campus_data?.campus_name}</p>
                                                </div>
                                                <div className="row gap-10 alignCenter">
                                                    <p style={{ backgroundColor: incident_color, padding: "5px 20px", fontSize: 13, color: "white", fontWeight: "bold" }}>{report.type}</p>
                                                    <p style={{ backgroundColor: color, padding: "5px 20px", fontSize: 13, color: "white", fontWeight: "bold" }}>{report.report_status}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })

                                    :
                                    <p>No reports today</p>
                                }
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div >
    )
}
