const tidyNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
}

const tidyDate = (date) => {
    if (!date) {
        return "";
    }
    //2023-07-13 15:27:57
    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if (!date?.includes("Z")) {
        date = date + "Z";
    }

    let dateObj = new Date(date);
    let day = dateObj?.getDate();
    let month = MONTHS[dateObj?.getMonth()];
    let year = dateObj?.getFullYear();
    let hour = dateObj?.getHours();
    let minute = dateObj?.getMinutes();

    if (day < 10) {
        day = "0" + day;
    }

    if (hour < 10) {
        hour = "0" + hour;
    }

    if (minute < 10) {
        minute = "0" + minute;
    }

    return `${day} ${month} ${year}, ${hour}:${minute}`;
}

const calculate_time_since = (date) => {
    if (!date) {
        return "";
    }
    //2023-07-13 15:27:57
    if (!date?.includes("Z")) {
        date = date + "Z";
    }

    let dateObj = new Date(date);
    let now = new Date();
    let diff = now - dateObj;

    let seconds = Math.floor(diff / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    if (seconds < 60) {
        return `${seconds > 0 ? seconds: 0} seconds ago`;
    }

    if (minutes < 60) {
        return `${minutes > 0 ? minutes : 0} minutes ago`;
    }

    if (hours < 24) {
        return `${hours > 0 ? hours : 0} hours ago`;
    }

    return `${days > 0 ? days : 0} days ago`;
}

const capitalised = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const isMobile = (width) => {
    return width < 768;
}

export {
    tidyNumber,
    tidyDate,
    capitalised,
    calculate_time_since,
    isMobile,
}